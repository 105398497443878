import $ from 'jquery';

class VideoController {
  constructor() {
    this.videos = $();

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.checkViewport = this.checkViewport.bind(this);

    $(window).on('scroll', this.onScroll);
    this.onScroll();
  }

  setupPage() {
    // clean previous videos listener to avoid memory leak
    this.videos.off('mouseenter mousemove', this.onMouseEnter);
    this.videos.off('mouseleave', this.onMouseLeave);
    this.videos.off('click', this.onClick);

    this.videos = $(`.post .project .video`);

    this.videos.on('mouseenter mousemove', this.onMouseEnter);
    this.videos.on('mouseleave', this.onMouseLeave);
    this.videos.on('click', this.onClick);
  }

  onMouseEnter(e) {
    $(e.currentTarget).addClass('hover');

    clearTimeout(this.timeourId);
    this.timeourId = setTimeout(() => this.onMouseLeave(e), 2000);
  }

  onMouseLeave(e) {
    $(e.currentTarget).removeClass('hover');

    clearTimeout(this.timeourId);
  }

  onClick(e) {
    this.onMouseEnter(e);

    const container = $(e.currentTarget);
    if (container.hasClass('paused')) {
      this.play(container);
    } else {
      this.pause(container);
    }
  }

  play(container) {
    container.removeClass('paused');
    container.find('video')[0].play();
  }

  pause(container) {
    container.addClass('paused');
    container.find('video')[0].pause();
  }

  onScroll() {
    this.videos.each(this.checkViewport);
  }

  checkViewport(index, video) {
    const container = $(video);

    const rect = video.getBoundingClientRect();
    const minY = -rect.height;
    const maxY = window.innerHeight;

    if (rect.y > minY && rect.y < maxY) {
      if (!container.hasClass('visible')) {
        container.addClass('visible')
        this.play(container);
      }
    } else {
      if (container.hasClass('visible')) {
        container.removeClass('visible')
        this.pause(container);
      }
    }
  }
}

export default new VideoController()
