import $ from 'jquery';
import anime from 'animejs';

class PageTransition {
  constructor() {
    this.container = $('#transition-bg, #transition-title');

    this.transitionBg = $('#transition-bg');
    this.bgTwen = this.transitionBg.find('.twen');

    this.transitionTitle = $('#transition-title');
    this.titleTwen = this.transitionTitle.find('.twen');
    this.letter = this.transitionTitle.find('.letter');
    this.word = this.transitionTitle.find('.word');
  }

  show(bgElement, bgZIndex, word, origin, destination, hideWord, callback) {
    const bgClone = bgElement ? $(bgElement).clone() : '';
    this.bgTwen.html(bgClone);

    this.letter.text(word.substring(0, 1));
    this.word.text(word.substring(1));

    this.container.css({
      visibility: 'visible',
    });

    this.transitionBg.css({
      'z-index': bgZIndex,
    });

    const letterHeight = this.letter.height() - 90; // 90 é um espaçamento visual da altura X da letra e seu height
    const isOutOfView = (origin.top < -letterHeight || destination.top < -letterHeight);

    const animeTime = isOutOfView ? 500 : 1000;

    anime({
      targets: this.bgTwen.find('.img-cover')[0],
      opacity: [0.5, 1],
      duration: animeTime,
      easing: [0.73, -0.01, 0.14, 0.99],
    });

    anime({
      targets: this.titleTwen[0],
      top: isOutOfView ? [origin.top, origin.top] : [origin.top, destination.top],
      left: isOutOfView ? [origin.left, origin.left] : [origin.left, destination.left],
      duration: animeTime,
      easing: [0.73, -0.01, 0.14, 0.99],
    });

    anime({
      targets: this.letter[0],
      opacity: isOutOfView ? [1, 0] : [1, 1],
      duration: animeTime,
    });

    anime({
      targets: this.word[0],
      opacity: isOutOfView ? [0, 0] : hideWord ? [1, 0] : [0, 1],
      duration: animeTime,
      easing: [0.73, -0.01, 0.14, 0.99],
      complete: () => {
        if (callback) callback(isOutOfView);
      },
    });
  }

  hide() {
    this.container.css({ visibility: 'hidden' });
  }
}

export default new PageTransition();
