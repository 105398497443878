import $ from 'jquery';

class HtmlLoader {
  constructor() {
    this.cacheHtml = {};
    this.cacheContent = {};
  }

  get(url) {
    if (!this.cacheContent[url]) {
      // https://stackoverflow.com/questions/15403600/jquery-not-finding-elements-in-jquery-parsehtml-result
      const html = this.cacheHtml[url];
      const content = $('<div>').append(html).find('#page-content').children();
      this.cacheContent[url] = content;
    }

    return this.cacheContent[url];
  }

  // usado para pegar o html antes de converter a página num objeto jquery
  // dessa forma a gente não precisa esperar o loading dos elementos da página, mas já trata o html
  // usado para resgatar as urls do projeto antes deles carregarem
  getHtml(url) {
    return this.cacheHtml[url];
  }

  async load(url) {
    const data = await $.get(url);
    const html = $.parseHTML(data);
    this.cacheHtml[url] = html;
  }

  async loadList(list) {
    const promises = list.map(async (i) => await this.load(i));
    await Promise.all(promises);
  }
}

export default new HtmlLoader();
