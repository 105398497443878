import $ from 'jquery';

import HtmlLoader from './HtmlLoader';
import PageTransition from './PageTransition';
import VideoController from './VideoController';

import './scss/style.scss';

// expoe o jquery para usar no console
window.$ = $;

// variáveis para controle da navegação
let prevUrl = '';
let currentUrl = location.href;
let nextUrl = '';
let isChangingPage = false;
let navigationTimer = null;
let lastWorksScrollPosition = 0;

// página específicas para tratamentos especiais
const aboutUrl = `${App.baseUrl}/`;
const worksUrl = `${App.baseUrl}/work/`;

// ativar os links internos para trocar via js
function enableLinks(url) {
  $(`a[href^="${App.baseUrl}"]`).on('click', onClickLink);

  $('.works.page .case-link-list a')
    .mouseover(function(e) {
      $('.works.page .header, .works.page .footer').addClass('mouseover-hide');
    }).mouseout(function() {
      $('.works.page .header, .works.page .footer').removeClass('mouseover-hide');
    });

  // const taina = $().

  // $( ".hide-and-see" ).click(function() {
  //   $( ".team" ).animate({
  //     opacity: 1,
  //     // left: "+=50",
  //     height: "toggle"
  //   }, 1500 );
  // });



  // $(".hide-and-see").click(function(){
  //   const has = $('.team').hasClass('hide');
  //   if(has) {
  //     $('.team').removeClass('hide')
  //     // .addClass('see');
  //     $(".team").slideDown(100);
  //   } else {
  //     $('.team').addClass('hide')
  //     // .addClass('hide');
  //     $(".team").slideUp(100);
  //   }
  // });


  $('.hide-and-see').click(function(){
    const has = $('.team').hasClass('hide');
    const hasBtn = $(this).hasClass('hide-btn');
    if(has && hasBtn) {
      $(this).addClass('up');
      $(this).find('.see-text').addClass('out');
      $(this).find('.hide-text').removeClass('out');
      // .text('Hide team');
      $('.team').removeClass('hide').addClass('see');
    } else {
      $(this).removeClass('up');
      // $(this).find('span').text('See team');
      $(this).find('.see-text').removeClass('out');
      $(this).find('.hide-text').addClass('out');

      $('.team').removeClass('see').addClass('hide');
    }
  });

  VideoController.setupPage();
}

// desabilita o click default do link e navega por js
function onClickLink(e) {
  e.preventDefault();

  // não faz nada se estiver trocando de página
  if (isChangingPage) {
    return;
  }

  // resgata a url do link
  const url = $(e.currentTarget).attr('href');

  // muda visualmente o conteúdo
  navigateTo(url, e);

  // muda a url do navegador
  history.pushState({}, '', url);
}

// função guarda-chuva para troca de página
function navigateTo(url, e) {
  nextUrl = url;

  // se estiver trocando de página, termina instantaneamente para liberar a próxima
  if (isChangingPage) {
    completeNavigation();
  }

  // muda a flag para travar a navegação enquanto está trocando
  isChangingPage = true;
  $('#giant-overlay').removeClass('unlock');

  // salva a posicao do scrollY para usar depois
  if (currentUrl === worksUrl) {
    lastWorksScrollPosition = window.scrollY;
  }

  // works -> about
  // single -> about
  if (nextUrl === aboutUrl) {
    transitionInstant();
  }
  else if (nextUrl === worksUrl) {
    // about -> works
    if (currentUrl === aboutUrl) {
      transitionInstant();
    }
    // single -> works
    else {
      transitionToWorks();
    }
  }
  else {
    // works -> single
    if (currentUrl === worksUrl) {
      transitionToSingle(e);
    }
    // single -> single
    else {
      transitionToSingle(e);
    }
  }
}

function transitionInstant() {
  prevUrl = currentUrl;
  currentUrl = nextUrl;
  nextUrl = '';
  completeNavigation();
}

// transitionToWorks é muito similar ao transitionToSingle
// mas optamos por duplicar para ter maior flexibilidade
function transitionToWorks() {
  const content = HtmlLoader.get(nextUrl);
  $('#next-page').html(content);
  const title = $('#page-content #single-title');
  const button = content.find(`.case-link-list a[href="${currentUrl}"]`);

  if (!title || !title.length || !button || !button.length) {
    transitionInstant();
    return;
  }

  title.css({ visibility: 'hidden' });

  const word = button.find('.letter, .word').text();
  const origin = title.offset();
  const destination = button.parent().offset();
  origin.top -= window.scrollY;
  destination.top -= window.scrollY;
  destination.top -= lastWorksScrollPosition;

  PageTransition.show(null, 1, word, origin, destination, false, (isOutOfView) => {
    completeNavigation(true, isOutOfView);
  });

  $('#page-content').attr('class', '').addClass('page-out');

  prevUrl = currentUrl;
  currentUrl = nextUrl;
  nextUrl = '';
}

function transitionToSingle(e) {
  const content = HtmlLoader.get(nextUrl);
  $('#next-page').html(content);
  const title = content.find('#single-title');
  const button = e ? $(e.currentTarget) : null;

  if (!title || !title.length || !button || !button.length) {
    transitionInstant();
    return;
  }

  button.css({ visibility: 'hidden' });

  const bgElement = button.find('.bg');
  const word = button.find('.letter, .word').text();
  const origin = button.parent().offset();
  const destination = title.offset();
  origin.top -= window.scrollY;
  destination.top -= window.scrollY;

  PageTransition.show(bgElement, 3, word, origin, destination, true, (isOutOfView) => {
    completeNavigation(true, isOutOfView);
  });

  $('#page-content').attr('class', '').addClass('page-out');

  prevUrl = currentUrl;
  currentUrl = nextUrl;
  nextUrl = '';
}

function completeNavigation(transitionIn, isOutOfView = false) {
  // injeta o html da nova página
  const content = HtmlLoader.get(currentUrl);
  $('#page-content').html(content.clone());
  $('#next-page').html('');
  enableLinks();

  // scroll para a posicao correta
  if (currentUrl === worksUrl) {
    if (!isOutOfView) {
      const button = $('#page-content').find(`.case-link-list a[href="${prevUrl}"]`);
      button.parent().addClass('skip-animation');
    }
    window.scroll(0, lastWorksScrollPosition);
  } else {
    window.scroll(0, 0);
  }

  // cor de fundo do body
  if (currentUrl === aboutUrl) {
    $('body').addClass('body-white');
  } else {
    $('body').removeClass('body-white');
  }

  // animacao de entrada
  $('#page-content').attr('class', '');
  if (transitionIn) {
    $('#page-content').addClass('page-in');
  }

  // delay de 1 frame para não piscar os elementos
  setTimeout(() => {
    PageTransition.hide();
  }, 50)

  // registra o page view no Google Analytics
  ga('set', 'page', location.pathname);
  ga('send', 'pageview');

  // libera novas trocas
  isChangingPage = false;
  $('#giant-overlay').addClass('unlock');
}

// inicializa o app
async function init() {

  // habilita os links para funcionar com js
  enableLinks();

  // escuta mudanças no history para trocar de página com o back do browser
  $(window).on('popstate', (e) => {
    navigateTo(location.href);
  });

  // preload about e works
  await HtmlLoader.loadList([aboutUrl, worksUrl]);

  // preload dos projetos
  const worksMenu = HtmlLoader.get(worksUrl);
  const links = worksMenu.find('.case-link-list a');
  const urls = [];
  links.each((index, element) => {
    urls.push($(element).attr('href'));
  });
  await HtmlLoader.loadList(urls);

  // remove a cortina inicial
  $('#giant-overlay').addClass('quit unlock');
}

init();
